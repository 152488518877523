<template>

  <div>


    <div class="row d-none">

      <div class="col-md-12">

        <!-- Load JSON data -->
        <div class="card ">
          <div class="card-header header-elements-inline">
            <h6 class="card-title">Profit & Loss</h6>
            <div class="header-elements">
              <div class="list-icons">
                <a class="list-icons-item" data-action="collapse"></a>
                <a class="list-icons-item" data-action="reload"></a>
                <a class="list-icons-item" data-action="remove"></a>
              </div>
            </div>
          </div>

          <div class="card-body">

            <div class="tree-ajax card card-body border-left-info border-left-2 shadow-0 rounded-left-0">
              <table class="table table-xs table-columned table-hover table-responsive">
                <thead>
                  <th>Liablities</th>
                  <th></th>
                  <th>Assets</th>
                  <th></th>
                </thead>
                <tbody>

                </tbody>
                <tfoot>

                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <!-- /load JSON data -->

      </div>
    </div>

    <div class="card">

      <div id="toolbar" class="fixed-table-toolbar" style="padding-right: 20px;padding-left: 20px;padding-top: 10px;">
        <div class="form-group row">
          <div class="col">
            <h5>Profit & Loss</h5>
          </div>


          <label class="col-md-1 col-form-label">From:</label>
          <div class="col-md-2">
            <input type="date"  class="form-control" placeholder="From Date"  v-model="from_date" @change="from_date_change" >
          </div>


          <label class="col-md-1 col-form-label">Upto:</label>
          <div class="col-md-2">
            <input type="date"  class="form-control" placeholder="From Date"  v-model="ason_date" >
          </div>

          <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadProfitandLoss">Show</button>
        </div>

      </div>


      <div class="card-body">

        <div class="row" >
          <div class="col-md-6" >
            <table  id="treeexpenses" class="table table-xs table-columned table-hover tree-table fancytree-container fancytree-ext-table" role="treegrid" aria-multiselectable="true" aria-readonly="true">
            <thead>
              <tr>
                <th class="d-none" style="width: 46px;"></th>
                <th style="width: 80px;">#</th>
                <th>Expenses</th>
                <th style="width: 150px;"></th>
              </tr>
            </thead>
            <tbody>

            </tbody>
              <tfoot style="background-color: lightgrey;" class="font-weight-semibold">


                <tr>
                  <td></td>
                  <td style="text-align: right;">Expense Total</td>
                  <td style="width: 120px;text-align: right;">{{expense_total | indianFormat}}</td>
                </tr>

                <tr v-if="(income_total - expense_total) > 0">
                  <td></td>
                  <td style="text-align: right;">Net Profit</td>

                  <td style="width: 120px;text-align: right;">{{  (income_total - expense_total ) > 0 ?  (income_total - expense_total )  : expense_total | indianFormat}}</td>

                </tr>

                <tr >
                  <td></td>
                  <td style="text-align: right;">Total</td>

                  <td style="width: 120px;text-align: right;">{{  (income_total - expense_total ) > 0 ?  ((income_total - expense_total ) + expense_total)  : expense_total | indianFormat}}</td>

                </tr>


              </tfoot>
          </table>
          </div>

          <div class="col-md-6" >
            <table id="treeincomes" class="table table-xs table-columned table-hover tree-table fancytree-container fancytree-ext-table" role="treegrid" aria-multiselectable="true" aria-readonly="true">
              <thead>
                <tr>
                  <th class="d-none" style="width: 46px;"></th>
                  <th style="width: 80px;">#</th>
                  <th>Incomes</th>
                  <th style="width: 150px;"></th>
                </tr>
              </thead>
              <tbody>

              </tbody>
              <tfoot style="background-color: lightgrey;" class="font-weight-semibold">

                <tr  v-if="income_total > 0" >
                  <td></td>
                  <td style="text-align: right;">Income Total</td>
                  <td style="width: 120px;text-align: right;">{{ income_total | indianFormat}}</td>
                </tr>

                <tr v-if="(income_total - expense_total) < 0">
                  <td></td>
                  <td style="text-align: right;">Net Loss</td>
                  <td style="width: 120px;text-align: right;">{{ Math.abs(income_total - expense_total ) | indianFormat}}</td>
                </tr>

                <tr>
                  <td></td>
                  <td style="text-align: right;">Total</td>
                  <td style="width: 120px;text-align: right;">{{  (income_total - expense_total ) < 0 ?  (Math.abs(income_total - expense_total ) + income_total) : income_total | indianFormat}}</td>
                </tr>

              </tfoot>
            </table>
          </div>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'ProfitandLossView',
    store,
    data () {
      return {
        current_ibr : localStorage.getItem('branch_code'),
        NumFormat : new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }),
        from_date: moment().add(-1,'month').format('YYYY-MM-DD'),
        // ason_date: moment().format('YYYY-MM-DD'),
        ason_date : moment().add(1,'M').add(-1,'days').format('YYYY-MM-DD'),
        fancytree: {},
        expense_total: 0.0,
        income_total : 0.0,
        total_diff : 0.0,
        data_loaded : false,
        income_loaded : false,
        pandl : {},
      }
    },
    created(){

    },
    mounted () {

      const self = this;


      // Dropdown submenu
      $('.context-submenu').contextmenu({
        target: '.context-sub-menu'
      }).on('hidden.bs.context',function () {
        $(this).find('.context-sub-menu .show').removeClass('show');
      });

      self.$data.ason_date = moment().format('YYYY-MM-DD');


    },
    filters:{
      formatDate(val){
        return val.slice(0, 10).split('-').reverse().join('-');
      },
      indianFormat (value) {

        if(value !== 0)
          return NumFormat.format(value);// value.toFixed(2);
        else
          return '';
      },
    },
    methods:{
      from_date_change(){
        this.$data.ason_date = moment(this.$data.from_date, 'YYYY-MM-DD').add(1,'M').add(-1,'days').format('YYYY-MM-DD');
      },
      loadProfitandLoss(){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.pandl = {};

        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/pandl/?ibr=${self.$data.current_ibr}&from=${self.$data.from_date}&upto=${self.$data.ason_date}`,requestOptions).then(userService.handleResponse).then(function (data) {
            self.$data.pandl =  data;

            self.$data.income_total = data.income_total;
            self.$data.expense_total = Math.abs(data.expense_total);

            self.showProfitandLoss(self.$data.pandl.incomes, self.$data.pandl.expenses);

        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      showProfitandLoss(incomes, expenses){
        const self = this;




        $(":ui-fancytree").fancytree("destroy");


        $('#treeexpenses').fancytree({
          extensions: ['table'],
          checkbox: false,
          cache: true,
          table: {
            indentation: 20,      // indent 20px per node level
            nodeColumnIdx: 2,     // render the node title into the 2nd column
            checkboxColumnIdx: 0  // render the checkboxes into the 1st column
          },
          source: expenses,
          renderColumns: function (event, data) {

            let node = data.node, $tdList = $(node.tr).find('>td');

            $tdList.eq(0).hide();
            $tdList.eq(1).text(node.getIndexHier()).addClass('alignRight')

            $tdList.eq(3).addClass('text-right').html(indianFormat( node.data.balance * -1 ));

          }
        });



        $('#treeincomes').fancytree({
          extensions: ['table'],
          checkbox: false,
          cache: true,
          table: {
            indentation: 20,      // indent 20px per node level
            nodeColumnIdx: 2,     // render the node title into the 2nd column
            checkboxColumnIdx: 0  // render the checkboxes into the 1st column
          },
          source: incomes,
          renderColumns: function (event, data) {

            let node = data.node, $tdList = $(node.tr).find('>td');

            $tdList.eq(0).hide();
            $tdList.eq(1).text(node.getIndexHier()).addClass('alignRight')

            $tdList.eq(3).addClass('text-right').html(indianFormat( node.data.balance));


          }
        });


        self.$data.data_loaded = false;
        self.$data.income_loaded = false;

      }
    }
  }
</script>

<style scoped>

</style>
